const Frameworks = [
  {
    label: 'Hiring',
    description: `Do you attract, develop and keep the right people? Are the right attitudes and values just as important as the right skills?`,
  },
  {
    label: 'Training',
    description: `Training: Are you training your people properly ? Do you invest in them upfront to ensure they have all the right skills and tools they need for success?`,
  },
  {
    label: 'Client Journey',
    description: `Is an optimal client journey in place? Did managers, support staff and sales teams help create the journey - - so it feels like it belongs to them?`,
  },
  {
    label: 'Sales Management',
    description: `Are your sales managers guiding the program you built? Are they managing in a way that develops your people and ensures all the outcomes you need?`,
  },
  {
    label: `Communication & Culture`,
    description: ` Are you communicating effectively and efficiently? Have you created a culture that supports learning and celebrates success?`,
  },
  {
    label: `Rewards & Remuneration`,
    description: ` Are you rewarding the whole journey and not just closed sales? Are you rewarding the right behaviors and outcomes?`,
  },
]

export default Frameworks
