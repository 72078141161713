import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 90px 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 40px 20px;
    gap: 30px;
  }
`

export const FrameworksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    grid-template-columns: 1fr;
  }
`
