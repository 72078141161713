import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 70px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  .title {
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 70px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 70px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 60px 20px;
  }
`

export const FlipCardsContainer = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;

  .flip-card {
    width: 22%;
  }

  @media (max-width: 1366px) {
    .flip-card {
      width: 46%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    display: none;
  }
`

export const ArticlesContainer = styled.article`
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.wxs}) {
    display: none;
  }

  .flex-wrapper {
    width: 100%;
  }
`
