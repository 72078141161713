import {
  FlexArticle,
  FlipCard,
  StyledText,
} from 'components/common'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'
import React from 'react'
import {
  ArticlesContainer,
  FlipCardsContainer,
  Wrapper,
} from './styled-components'

const TrendsSection = ({
  title,
  articles,
}: {
  title: string
  articles: {
    frontTitle: string
    backTitle: string
    backContent: string
  }[]
}) => {
  const frontSvgs = [
    'information',
    'options',
    'skepticism',
    'dislike',
  ]
  return (
    <Wrapper>
      <StyledText className="heading">{title}</StyledText>
      <FlipCardsContainer>
        {articles.map((article, index) => (
          <FlipCard
            key={index}
            {...article}
            frontSvg={frontSvgs[index]}
          />
        ))}
      </FlipCardsContainer>
      <ArticlesContainer>
        {articles.map(
          ({ frontTitle, backContent }, index) => {
            let icon
            switch (frontSvgs[index]) {
              case 'information':
                icon = currentIconEnums.moreInformation
                break
              case 'options':
                icon = currentIconEnums.moreOptions
                break
              case 'skepticism':
                icon = currentIconEnums.moreSkepticism
                break
              case 'dislike':
                icon = currentIconEnums.moreDissatisfaction
                break
            }
            const args = {
              icon,
              title: frontTitle,
              description: backContent,
            }
            return (
              <FlexArticle
                key={index}
                {...args}
                aligment="center"
                gap={[16, 8]}
              />
            )
          },
        )}
      </ArticlesContainer>
    </Wrapper>
  )
}

export default TrendsSection
