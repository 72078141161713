import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 70px 165px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${({ theme }) => theme.placeholders.maxWidth}

  .middle {
    text-align: center;
    font-family: 'Poppins-Light', sans-serif;
    margin-block: 40px;
    color: #636369;

    & > :last-child {
      display: none;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      margin-block: 15px;
      & > :last-child {
        font-size: 18px;
        display: block;
      }

      & > :first-child {
        display: none;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 60px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 50px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 40px 20px;
  }
`

export const AproachContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-article {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column-reverse;
    gap: 30px;

    .left-article {
      width: 100%;
      gap: 20px;
    }

    .right-article {
      width: 100%;
      gap: 20px;
    }

    .link,
    .custom-button {
      width: 100%;
      margin-top: 10px;
    }
  }
`

export const CheckContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    grid-template-columns: 1fr;
  }
`
