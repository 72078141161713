import {
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import React from 'react'
import {
  ArticleContainer,
  Wrapper,
} from './styled-components'

const SolutionSection = ({
  title,
  description,
  articles,
}: {
  title: string
  description: string
  articles: string
}) => {
  return (
    <WaveWrapper color="lightGrey-sandwitch">
      <Wrapper>
        <StyledText className="heading">{title}</StyledText>
        <ArticleContainer>
          <div className="left-article">
            <StyledText
              variant="light"
              className="subheader"
            >
              {description}
            </StyledText>
            <div className="responsive-top">
              <StyledButton
                paddings={74}
                to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
              >
                Let’s talk business
              </StyledButton>
            </div>
          </div>
          <div
            className="right-article"
            dangerouslySetInnerHTML={{ __html: articles }}
          ></div>
          <div className="responsive">
            <StyledButton
              paddings={74}
              to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            >
              Let’s talk business
            </StyledButton>
          </div>
        </ArticleContainer>
      </Wrapper>
    </WaveWrapper>
  )
}

export default SolutionSection
