import {
  InnerArticleExtended,
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import { FrameworksProps } from 'interfaces/PageInterfaces'
import React from 'react'
import {
  FrameworksContainer,
  Wrapper,
} from './styled-components'

const TransformSection = ({
  title,
  frameworks,
}: FrameworksProps) => {
  return (
    <WaveWrapper color="lightGrey-reversed">
      <Wrapper>
        <StyledText textAlign="center" className="heading">
          {title}
        </StyledText>
        <FrameworksContainer>
          {frameworks.map((framework, index) => (
            <InnerArticleExtended
              key={index}
              {...framework}
              doubleArrow={true}
            />
          ))}
        </FrameworksContainer>
        <StyledButton
          paddings={74}
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        >
          Let’s talk business
        </StyledButton>
      </Wrapper>
    </WaveWrapper>
  )
}

export default TransformSection
