import { StyledButton, StyledText } from 'components/common'
import React from 'react'
import { Article, Wrapper } from './styled-components'
import TechLearningSvg from 'images/svg/websiteWoman.svg'

const IntroduceSection = ({
  description,
}: {
  description: string
}) => {
  return (
    <Wrapper>
      <Article>
        <StyledText variant="light" className="subheader">
          {description}
        </StyledText>
        <StyledButton
          paddings={74}
          to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
        >
          Let’s talk business
        </StyledButton>
      </Article>
      <TechLearningSvg />
    </Wrapper>
  )
}

export default IntroduceSection
