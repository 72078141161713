import {
  StyledButton,
  StyledText,
  WaveWrapper,
} from 'components/common'
import React, { isValidElement } from 'react'
import {
  TextContainer,
  FlexWrapper,
} from './styled-components'

import BannerSparkle from 'images/svg/bannerSparkle.svg'
import { TitleProps } from 'interfaces/PageInterfaces'

const BannerSection = ({
  header,
  subHeader,
}: TitleProps) => {
  const sparkleMap = [1, 2, 3, 4, 5]
  return (
    <WaveWrapper color="lightGreen">
      {sparkleMap.map((sparkle, index) => {
        const className = `banner-sparkle sparkle-${sparkle}`
        return (
          <BannerSparkle
            className={className}
            key={index}
          />
        )
      })}
      <FlexWrapper>
        <TextContainer>
          <StyledText textAlign="center" fontSize="72px">
            {header}
          </StyledText>
          {isValidElement(subHeader) && (
            <StyledText
              textAlign="center"
              fontSize="24px"
              fontType="regular"
              fontWeight={400}
            >
              {subHeader}
            </StyledText>
          )}
          <StyledButton
            paddings={74}
            to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
          >
            Let’s talk business
          </StyledButton>
        </TextContainer>
      </FlexWrapper>
    </WaveWrapper>
  )
}

export default BannerSection
