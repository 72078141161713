import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 70px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 70px 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 40px 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    flex-direction: column-reverse;
    align-items: center;

    & > :last-child {
      width: 90%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 0 20px 30px 20px;
  }
`

export const Article = styled.article`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .link,
  .custom-button {
    width: unset;
  }

  .link {
    display: inline-block;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    width: 100%;

    .link,
    .custom-button {
      width: 100%;
    }
  }
`
