import React, { useState } from 'react'
import { UsersReviewCarousel } from 'components/carousels'
import { ModalTestimonial } from 'components/common'
import { Wrapper } from './styled-components'
import { GlobalStyle } from 'layouts'
import { IGatsbyImageData } from 'gatsby-plugin-image'

const WhoWeHelpedSection = ({
  reviews,
}: {
  reviews: {
    profilePicture: {
      gatsbyImage: IGatsbyImageData
    }
    fullName: string
    designation: string
    review: {
      review: string
    }
  }[]
}) => {
  const [activeReview, setActiveReview] = useState(-1)

  return (
    <>
      <GlobalStyle isNavOpen={activeReview !== -1} />
      <ModalTestimonial
        {...reviews[activeReview]}
        onClose={() => setActiveReview(-1)}
      />

      <Wrapper>
        <UsersReviewCarousel
          reviews={reviews}
          setActiveReview={setActiveReview}
          activeReview={activeReview}
        />
      </Wrapper>
    </>
  )
}

export default WhoWeHelpedSection
