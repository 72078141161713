import {
  Framework,
  StyledButton,
  StyledText,
} from 'components/common'
import React from 'react'
import {
  AproachContainer,
  CheckContainer,
  Wrapper,
} from './styled-components'
import PeopleOnLaptop from 'images/svg/peopleOnLaptop.svg'
import Frameworks from './FrameWorks'

const AproachSection = ({
  title,
  description,
  frameworkTitle,
  frameworks,
}: {
  title: string
  description: string
  frameworkTitle: string
  frameworks: { label: string; description: string }[]
}) => {
  return (
    <Wrapper>
      <AproachContainer>
        <div className="left-article">
          <StyledText className="heading">
            {title}
          </StyledText>
          <StyledText variant="light" className="subheader">
            {description}
          </StyledText>
          <div className="responsive-top">
            <StyledButton
              paddings={74}
              to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            >
              Let’s talk business
            </StyledButton>
          </div>
        </div>
        <PeopleOnLaptop />
      </AproachContainer>
      <StyledText className="heading middle">
        <span>{frameworkTitle}</span>
        <span className="sub">
          Solve your biggest challenges and build a sales
          team that’s prepared for anything.
        </span>
      </StyledText>
      <CheckContainer>
        {Frameworks.map((framework, index) => (
          <Framework {...framework} key={index} />
        ))}
      </CheckContainer>
    </Wrapper>
  )
}

export default AproachSection
