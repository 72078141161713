import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.placeholders.maxWidth};
  margin-top: -1px;

  padding: 0 165px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wl}) {
    padding: 0 100px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wm}) {
    padding: 0 50px;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    padding: 20px;

    & > :first-child {
      text-align: center;
    }
  }
`

export const ArticleContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 80px;

  .left-article {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .right-article {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    p {
      color: rgba(99, 99, 105, 1);
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      line-height: 1.5;

      strong {
        color: black;
        font-family: 'Poppins-SemiBold', sans-serif;
      }
    }
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.wxs}) {
    flex-direction: column;
    gap: 30px;

    .left-article {
      width: 100%;
      gap: 20px;
    }

    .right-article {
      width: 100%;
      gap: 20px;
    }

    .link,
    .custom-button {
      width: 100%;
      margin-top: 10px;
    }
  }
`
