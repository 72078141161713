import { graphql } from 'gatsby'
import { IConsultancyPageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'
import { TrustedCompaniesSection } from 'sections/Businesses'
import {
  AproachSection,
  BannerSection,
  IntroduceSection,
  SolutionSection,
  TrendsSection,
  TransformSection,
  TrainingSection,
  TestimonialsSection,
  ConnectSection,
} from 'sections/Consultancy'

const Consultancy = ({ data }: IConsultancyPageProps) => {
  const {
    partners,
    bannerText,
    missionText,
    trends,
    solution,
    approach,
    trainingTexts,
    transformText,
    steps,
    frameworks,
    testemonials,
  } = data
  const trendsArgs = {
    title: trends.block3Trends,
    articles: [
      {
        frontTitle: trends.block3MoreInformationFrontTitle,
        backTitle: trends.block3MoreInformationBackTitle,
        backContent:
          trends.block3MoreInformationBackContent
            .block3MoreInformationBackContent,
      },
      {
        frontTitle: trends.block3MoreOptionsFrontTitle,
        backTitle: trends.block3MoreOptionsBackTitle,
        backContent:
          trends.block3MoreOptionsBackContent
            .block3MoreOptionsBackContent,
      },
      {
        frontTitle: trends.block3MoreSkepticismFrontTitle,
        backTitle: trends.block3MoreSkepticismBackTitle,
        backContent:
          trends.block3MoreSkepticismBackContent
            .block3MoreSkepticismBackContent,
      },
      {
        frontTitle:
          trends.block3MoreDissatisfactionFrontTitle,
        backTitle:
          trends.block3MoreDissatisfactionBackTitle,
        backContent:
          trends.block3MoreDissatisfactionBackContent
            .block3MoreDissatisfactionBackContent,
      },
    ],
  }

  const solutionArgs = {
    title: solution.title,
    description: solution.description.description,
    articles: solution.articles.childMarkdownRemark.html,
  }

  const approachArgs = {
    title: approach.title,
    description: approach.description.description,
    frameworkTitle: approach.frameworksTitle,
  }

  const trainingArgs = {
    title: trainingTexts.title,
    description: trainingTexts.description.description,
  }

  const frameworkArgs = frameworks.nodes
    .sort((a, b) => a.index - b.index)
    .map((framework) => ({
      label: framework.label,
      description: framework.description.description,
    }))

  const stepArgs = steps.nodes
    .sort((a, b) => a.index - b.index)
    .map((step) => ({
      title: step.title,
      description: step.description.description,
    }))

  return (
    <>
      <Helmet title="Consultancy" defer={false} />
      <BannerSection {...bannerText} />
      <IntroduceSection
        description={missionText.description.description}
      />
      <TrendsSection {...trendsArgs} />
      <SolutionSection {...solutionArgs} />
      <AproachSection
        {...approachArgs}
        frameworks={frameworkArgs}
      />
      <TransformSection
        {...transformText}
        frameworks={stepArgs}
      />
      <TestimonialsSection reviews={testemonials.nodes} />
      <TrustedCompaniesSection partners={partners.nodes} />
      {/* <ConnectSection /> */}
      <TrainingSection {...trainingArgs} />
    </>
  )
}

export default Consultancy

export const query = graphql`
  query {
    bannerText: contentfulConsultancyPage {
      header: block1BannerHeader
      subHeader: block1BannerHeader
    }
    partners: allContentfulPartnerCompanies {
      nodes {
        logo {
          gatsbyImageData(width: 150, quality: 80)
        }
      }
    }
    missionText: contentfulConsultancyPage {
      description: block2MissionDescription {
        description: block2MissionDescription
      }
    }
    trends: contentfulConsultancyPage {
      block3MoreDissatisfactionBackTitle
      block3MoreDissatisfactionFrontTitle
      block3MoreInformationBackTitle
      block3MoreInformationFrontTitle
      block3MoreOptionsBackTitle
      block3MoreOptionsFrontTitle
      block3MoreSkepticismBackTitle
      block3MoreSkepticismFrontTitle
      block3MoreSkepticismBackContent {
        block3MoreSkepticismBackContent
      }
      block3MoreOptionsBackContent {
        block3MoreOptionsBackContent
      }
      block3MoreInformationBackContent {
        block3MoreInformationBackContent
      }
      block3MoreDissatisfactionBackContent {
        block3MoreDissatisfactionBackContent
      }
      block3Trends
    }
    solution: contentfulConsultancyPage {
      description: block4SolutionDescription {
        description: block4SolutionDescription
      }
      articles: block4SolutionArticles {
        childMarkdownRemark {
          html
        }
      }
      title: block4SolutionTitle
    }
    approach: contentfulConsultancyPage {
      title: block5FlexibleTitle
      description: block5FlexibleDescription {
        description: block5FlexibleDescription
      }
      frameworksTitle: block6FrameworksTitle
    }
    trainingTexts: contentfulConsultancyPage {
      title: blockLastTitle
      description: blockLastDescription {
        description: blockLastDescription
      }
    }
    transformText: contentfulConsultancyPage {
      title: block7TransformTitle
    }
    steps: allContentfulConsultancyStep {
      nodes {
        index
        title
        description {
          description
        }
      }
    }
    frameworks: allContentfulConsultancyFramework {
      nodes {
        index
        label
        description {
          description
        }
      }
    }
    testemonials: allContentfulConsultancyTestemonial {
      nodes {
        fullName
        designation
        review {
          review
        }
        profilePicture {
          gatsbyImage(width: 100, quality: 80)
        }
        # embeddedVideo {
        #   childMarkdownRemark {
        #     html
        #   }
        # }
      }
    }
  }
`
